.bodyThankPoint {
    background: #e4f4ff; 
    border-radius: 15px;
    padding: 30px 10px 0px 10px;
}

.bodyThankPoint h3 {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.bodyThankPoint2 h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
}

.button {
    width: 100px;
}

.center-left{
    display: flex;
    justify-content: center;
}

@media (max-width: 426px) {
    .center-left {
        display: flex;
        justify-content: left;
    }
}