.sign_canvas .konvajs-content>canvas {
  border: solid 2px black !important;
  border-radius: 10px !important;
}

.disable_sign_canvas .konvajs-content>canvas {
  border: solid 2px black !important;
  border-radius: 10px !important;
  background-color: #ECECEC !important;
}

.sign_btn {
  border: solid 2px black !important;
  border-radius: 10px !important;
}

.sign_btn:hover {
  background-color: #ECECEC;
}

.sign_btn:active {
  background-color: #80BDFF;
}

.sign_btn_click {
  background-color: #80BDFF;
}