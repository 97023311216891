.p-calendar.p-calendar-w-btn .p-inputtext {
    width: 170px;
    border-right: 0px;

    &:hover {
        border-left: 1px solid #CED4DA;
        border-top: 1px solid #CED4DA;
        border-bottom: 1px solid #CED4DA;;
    }
}
.p-calendar.p-calendar-w-btn .p-datepicker-trigger {
    color: grey;
    border-left: 0px;
    border-right: 1px solid #CED4DA;
    border-top: 1px solid #CED4DA;
    border-bottom: 1px solid #CED4DA;
    background-color: white;
}