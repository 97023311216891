
.add-title-iot h1 {
    font-size: 32px;
}

.add-title-iot{
    display: flex;
    justify-content: center;
}

.add-title-iot h1{
    margin-left: 10px;
    margin-bottom: 0px;
    /* font-size: 15px; */
}

.bottom-phase-iot {
    display: flex;
    gap: .5rem;
    margin-bottom: .5rem;
    justify-content: flex-end;
}