/* -------------------------------------------------------------------------- */
.flexDirectionChange {
  flex-direction: column;
  margin: 0px 5px 0px 4px;
}

@media (max-width: 1416px) {
  .flexDirectionChange {
    flex-direction: row;
    margin: 10px 0px 0px 0px;
  }
}

/* -------------------------------------------------------------------------- */

/* -------------------- Multi Slide -------------------- */
.containerMultiSlide {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.slider-multi {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  display: inline-flex;
  margin: 0px 10px;
}

.slider-multi::-webkit-scrollbar {
  display: none;
}

/* .slider-multi-icon .left,
.slider-multi-icon .right {
  background: black;
  border-radius: 100%;
  position: absolute;
  color: white;
  cursor: pointer;
} */

.slider-multi-icon.left {
  left: 0;
  z-index: 1;
}

.slider-multi-icon.right {
  right: 0;
  z-index: 1;
}

.react-datepicker-time__input {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
  border: 0.5px solid !important;
}

.react-datepicker-time__caption {
  font-size: 1.1rem !important; 
  margin-right: .5rem;
}

/* .slider-card {
  display: inline-flex;
} */
/* -------------------- Multi Slide -------------------- */

/* -------------------- Image Slide 1 -------------------- */
/* .containerStyles{
  width: 500px;
  height: 280px;
  background-color: aquamarine;
}
.sliderStyles{
  height: 100%;
  position: relative;
}
.slideStyles{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.slider-left{
  position: absolute;
  top: 50%;
  transform: translate(0, -5%);
  left: 32px;
  color: "#fff";
  z-index: 1;
  cursor: pointer;
}
.slider-right{
  position: absolute;
  top: 50%;
  transform: translate(0, -5%);
  right: 32px;
  color: "#fff";
  z-index: 1;
  cursor: pointer;
}
.slide{
  opacity: 0;
  transition-duration: 1s ease;
}
.slide.active{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.0);
} */
/* -------------------- Image Slide 1 -------------------- */

/* -------------------- Image Slide 2 -------------------- */
.containerImageSlide2 {
  max-width: 500px;
  max-height: 280px;
  aspect-ratio: 500 / 280;
  width: 100%;
  height: 100%;
}

.slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.containerSlide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.slides {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform ease-out 0.2s;
}

.slide {
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  border: none;
}

.slider-left:focus {
  border: none;
  outline: none;
}

.slider-left:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.slider-right {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;
  border: none;
}

.slider-right:focus {
  border: none;
  outline: none;
}

.slider-right:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.slider-to {
  display: flex;
  justify-content: center;
  z-index: 1;
}

.dots-to {
  width: 7px;
  height: 7px;
  margin: -12px 5px;
  z-index: 1;
  border-radius: 100%;
  background-color: rgba(138, 138, 138, 0.5);
  border: 0.2px solid #003583;
  cursor: pointer;
}

.dots-to.active {
  width: 7px;
  height: 7px;
  margin: -12px 5px;
  z-index: 1;
  border-radius: 100%;
  background-color: #0395ff;
  border: 0.2px solid #0395ff;
  cursor: pointer;
}

.dots-to1 {
  width: 2%;
  height: 2%;
  aspect-ratio: 15 / 15;
  margin: 0px 5px;
  z-index: 1;
  border-radius: 100%;
  background-color: rgba(138, 138, 138, 0.5);
  border: 0.2px solid #f03e2b;
  cursor: pointer;
}

.dots-to1.active {
  width: 2%;
  height: 2%;
  aspect-ratio: 15 / 15;
  margin: 0px 5px;
  z-index: 1;
  border-radius: 100%;
  background-color: #f03e2b;
  border: 0.2px solid #f03e2b;
  cursor: pointer;
}

/* -------------------- Image Slide 2 -------------------- */

/* -------------------- Pagination -------------------- */
.pagination2 {
  display: inline-block;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.page-item2 {
  display: inline;
}

.page-item2:first-child .page-link2 {
  margin-left: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.page-item2:last-child .page-link2 {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.page-item2.active .page-link2,
.page-item2.active .page-link2:focus,
.page-item2.active .page-link2:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item2.disabled .page-link2,
.page-item2.disabled .page-link2:focus,
.page-item2.disabled .page-link2:hover {
  color: #818a91;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.page-link2 {
  position: relative;
  float: left;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  color: #007bff;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
}

.page-link2:focus,
.page-link2:hover {
  color: #007bff;
  background-color: #eceeef;
  border-color: #ddd;
}

/* show - no show */
.pagination_show_1 {
  display: flex;
  justify-content: center;
}

.pagination_show_2 {
  display: none;
}

@media (max-width: 390px) {
  .pagination_show_1 {
    display: none;
  }

  .pagination_show_2 {
    display: flex;
    justify-content: center;
  }
}

/* -------------------- Pagination -------------------- */

/* -------------------- Add On -------------------- */
.hiddenScroll {
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hiddenScroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.customScroll-1 {
  overflow: overlay !important;
}

.customScroll-1::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
}

/* .customScroll-1:hover:-webkit-scrollbar {
  height: 8px;
} */

.customScroll-1:hover::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.rowBrand {
  height: 105px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.colBrand {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.shadowProductService {
  box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.3) !important;
}

.roundedProductService {
  border-radius: 10px !important;
}

.textVerticalOverflow-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textVerticalOverflow-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* -------------------- Add On -------------------- */

/* -------------------- Point Cart Line -------------------- */
.Point_Cart_Line_font_1 {
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 461px) {
  .Point_Cart_Line_font_1 {
    font-size: 16px;
  }
}

@media (max-width: 402px) {
  .Point_Cart_Line_font_1 {
    font-size: 12px;
  }
}

@media (max-width: 345px) {
  .Point_Cart_Line_font_1 {
    font-size: 8px;
  }
}

.Point_Cart_Line_font_2 {
  color: white;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 461px) {
  .Point_Cart_Line_font_2 {
    font-size: 8px;
  }
}

@media (max-width: 402px) {
  .Point_Cart_Line_font_2 {
    font-size: 6px;
  }
}

@media (max-width: 345px) {
  .Point_Cart_Line_font_2 {
    font-size: 4px;
  }
}

@media (max-width: 380px) {
  .image-res {
    height: 300px !important;
  }

  .img-response-slice {
    width: 80px !important;
    height: 80px !important;
  }
}

/* -------------------- Point Cart Line -------------------- */

.Modal_Center_LeftRight {
  margin: 1.75rem auto;
}

/* -------------------- Product Status Card -------------------- */
/* responsive 595 412 */
.pstcard_img {
  border: solid 1px #003583;
  border-radius: 15px;
  width: 250px;
  height: 250px;
  aspect-ratio: 250 / 250;
  margin: 15px;
  background-color: white;
}

@media (max-width: 594px) {
  .pstcard_img {
    border: solid 1px #003583;
    border-radius: 15px;
    width: 67px;
    height: 67px;
    aspect-ratio: 250 / 250;
    margin: 15px;
    background-color: white;
  }
}

.pstcard_text {
  min-width: 130px;
}

@media (max-width: 411px) {
  .pstcard_text {
    min-width: 100px;
  }
}

/* -------------------- Product Status Card -------------------- */

/* -------------------- Product Service Category -------------------- */
.psecat_grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.psecat_card {
  margin: 20px;
}

@media (max-width: 555px) {
  .psecat_grid {
    grid-template-columns: 1fr 1fr;
  }

  .psecat_card {
    margin: 10px 5px;
  }
}

/* -------------------- Product Service Category -------------------- */

/* -------------------- Product Service Card -------------------- */
.psecard_size {
  width: 225px;
  height: 320px;
}

.psecard_text_1 {
  font-size: 24px;
}

.psecard_text_2 {
  font-size: 16px;
}

.psecard_text_3 {
  font-size: 20px;
}

.psecard_text_4 {
  font-size: 13px;
}

.psecard_dis_size {
  width: 40px;
  height: 26px;
}

.psecard_dis_text {
  font-size: 20px;
}

@media (max-width: 555px) {
  .psecard_size {
    width: 142px;
    height: 220px;
  }

  .psecard_text_1 {
    font-size: 15px;
  }

  .psecard_text_2 {
    font-size: 10px;
  }

  .psecard_text_3 {
    font-size: 15px;
  }

  .psecard_text_4 {
    font-size: 7px;
  }

  .psecard_dis_size {
    width: 24px;
    height: 16px;
  }

  .psecard_dis_text {
    font-size: 11px;
  }
}

/* -------------------- Product Service Card -------------------- */

/* -------------------- All Product Service -------------------- */
.aps_slide {
  margin: 10px;
}

.aps_pro_card {
  margin: 0px 10px;
}

@media (max-width: 555px) {
  .aps_slide {
    margin: 5px;
  }

  .aps_pro_card {
    margin: 0px 5px;
  }
}

/* -------------------- All Product Service -------------------- */