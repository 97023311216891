.hiddenScroll {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hiddenScroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.customScroll-1 {
  overflow: overlay !important;
}

.customScroll-1::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
  width: 8px;
}

/* .customScroll-1:hover:-webkit-scrollbar {
  height: 8px;
} */

.customScroll-1:hover::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.textVerticalOverflow-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textVerticalOverflow-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (min-width: 1200px) {
  .warpRow-1 {
    flex-wrap: nowrap !important;
  }
}

@media screen and (min-width: 768px) {
  .warpRow-1 {
    flex-wrap: wrap;
  }
}