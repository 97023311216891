.textWhite {
  color: white;
}

.textBlack {
  color: black;
}

.topHeadTable-Table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.topHeadTable-Thead {
  position: sticky;
  top: 0px;
}

.cardCountSize {
  margin: 0px 0px 15px 0px;
  min-width: 180px;
}

.cardCountComponentNormal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 170px;
  height: 80px;
  border: solid 3px black;
  white-space: nowrap;
}

.cardTableComponentNormal {
  display: flex;
  flex-direction: column;
  width: 100%;
}