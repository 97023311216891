.d-tree-container{
    list-style: none;
    padding: 0;
}


.d-tree-node{
    
    /* border-bottom: 1px solid lightgrey center;
    border-left: 1px solid lightgrey center; */

      /* height: 28px;
  top: -17px;
  width: 20px;
  left: -28px; */
    padding-top: 0.75rem ;
    padding-left: 1.25rem;
}

/* .d-tree-toggler.active{
    background-color: #000;
    transform: rotate(45deg);

} */