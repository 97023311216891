/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@import url(http://fonts.googleapis.com/css?family=Open+Sans);
@import url(http://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.overview {
    color: #55677C;
    font-weight: 700;
}
.monitor_title {
    display: flex;
    font-family: 'Inter', sans-serif;
    margin-left: 15px;
}

.monitor_title .title{
    margin-left: 15px;
    white-space: nowrap;
}

.monitor_title .title h1{
    color: #112A46;
    font-size: 25px;
    font-weight: 1000;
}

.monitor_title .title span{
    background: #007BFF;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.date-display {
    color: #55677C;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

/* .card-title{
    font-size: 14px;
    color: #112A46;
    font-weight: 400;
    opacity: 0.6;
} */

.card-value {
    font-size: 24px;
    font-weight: 550;
}

.monitor_card {
    display: flex;
    flex-direction: column;
}

.card {
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: 10px;
}

.card-image {
    width: 27px;
}

.card-image-little {
    width: 40px;
}

.card-monitor {
    display: flex;
    align-items: center;
    padding-left: 30px;

}

.multi-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 250px;
    font-size: 15px;
    font-weight: 300;

}

.multi-button button {
    flex: 1;
    position: relative;
    padding: 4px 2px;
    border: 1px solid #ABACAE;

    letter-spacing: 1px;
    color: #ABACAE;
    background: transparent;
    overflow: hidden;
    font-weight: 400;
    height: 40px;
}

.multi-button button.first {
    border-radius: 5px 0px 0px 5px;
    background-color: #3579F6;
    color: #fff;
}

.multi-button button.third {
    border-radius: 0px 5px 5px 0px;
}

.multi-button button:hover {
    color: #fff;
    transition: 0.2s ease-in-out;
    background-color: #3579F6;
}




@media only screen and (max-width: 1410px) {
    .card-value {
        font-size: 40px;     
    }
    /* .card-title{
        font-size: 15px;        
    } */
}

@media only screen and (max-width: 1260px) {
    .card-value {
        font-size: 30px;
        
    }
}

@media only screen and (max-width: 1100px) {
    .card-value {
        font-size: 25px;
        
    }
}

@media only screen and (max-width: 1023px) {
    .card-value {
        font-size: 50px;    
    }
}

.chart {
    margin-top: 20px;
}

.fade-transition {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
  
.fade-transition.active {
    opacity: 1;
}

.circle {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
}

.monitor-list-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
}

.monitor-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 150px; */
}

.listt .monitor-list {
    margin-top: 4px;
}

.list-value {
    font-weight: 300;
    font-size: 14px;
}

.monitor-list p, .monitor-list-main p{
    font-size: 14px;
    font-weight: 300;
}

.monitor-list-main p {
    margin-right: 90px;
}

.listt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2px;
    margin-left: 3px
}


.card-list-main {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-list{
    width: 100%;
    /* width: max-content; */
    /* margin-left: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-chart{
    margin-left: 30px;
    margin-top: 20px;
}

.title-chart h2{
    font-size: 21px;
    font-weight: 700;
    color: #112A46;
}

.title-chart p{
    font-weight: 300;
    font-size: 14px;
    color: #C8CACF;
}

.card-header-db-power {
    margin-left: -5rem;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.50);;
}

.card-headers h2{
    font-size: 16px;
}

.title-type {
    font-weight: 400;
    font-size: 1.5rem;
    justify-content: space-between;
    margin-left: 5px;
    /* margin-bottom: 10px; */
}

.title-type h2 {
    margin-left: 5px;
}

.card-headers {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, 0.30);
    border-bottom-width: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.25rem/* 8px */;
    padding-bottom: 0.25rem/* 8px */;
    padding-right: 0.75rem/* 12px */;
    flex-direction: column;
}

.content-db-power {
    font-family: 'Inter', sans-serif;
}

/* .form-frame {
    width: 150%;
} */

.time-title {
    font-size: 12px;
    font-weight: 50;
    display: flex;
    justify-content: center;
    width: 210%;
    padding-bottom: 1px;
    /* font-family: sans-serif; */
    /* margin-bottom: 20px; */
}

.chart-title {
    font-size: 12px;
    font-weight: 50;
    /* font-family: sans-serif; */
}


.monitor_card.card-body {
    margin-left: 20px;
}

.sorted-list{
    font-weight: 800;
    margin-left: 60px;
    margin-top: 30px
}


.power-monitor-list-title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 300;
    color: #bbb;
}


.current-chart-container {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.filter-container {
    margin-top: 15px;
}


.power-monitor-list-container {
    margin-top: 5px;
}

.power-monitor-list-container li {
    font-size: 16px;
    padding: 5px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.power-monitor-list-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.power-monitor-list-item .circle {
    width: 15px;
    height: 15px;
    margin-top: 5px;
}

.power-monitor-list-item .item {
    flex: 1;
    font-weight: 500;
    margin-left: 10px;
    margin-right: 70px;
}

.power-monitor-list-item .item-value {
    font-weight: 200;
}

/*  */
.grid-card-db-power-monitor {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem; /* ห่างระหว่างขอบคอลัมน์ */
}
.grid-search-db-power-monitor {
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: repeat(4, 1fr); */
}
.grid-power-energy-db-p-m {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
}

/* สำหรับขนาดจอขนาดใหญ่ (lg) */
@media only screen and  (max-width: 1260px) {
    .grid-card-db-power-monitor {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.25rem; /* ห่างระหว่างขอบคอลัมน์ */
    }
}

@media only screen and  (max-width: 1060px) {
    .grid-search-db-power-monitor {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1.25rem;
    }
}

  /* สำหรับขนาดจอขนาดเล็ก (sm) */
@media only screen and  (max-width: 767px) {
    .grid-card-db-power-monitor {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem; /* ห่างระหว่างขอบคอลัมน์ */
    }
  }
  
  /* สำหรับขนาดจอมือถือ (xs) - เวลาไม่ได้กำหนดขนาดจอแต่ละชนิด */
  @media only screen and  (max-width: 576px) {
    .grid-card-db-power-monitor {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1.25rem; /* ห่างระหว่างขอบคอลัมน์ */
    }
  }
  