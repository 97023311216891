.no-border {
    border: 0 !important;
}

.text-color {
    color: blue !important;
}

.header-input {
    margin: 0 1em;
}

.flex-contain {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.btn-hov:hover {
    color: white !important;
    background-color: rgb(71, 71, 71) !important;
}

.border-img {
    border: 1px solid #b6b6b6 !important;
    background-color: white !important;
}

.flex-contain-item {
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

input.checkbox-cute-plus[type="checkbox"] {
    background: #146dfd;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 2px;
    display: flex;
    /* position: relative; */
    justify-content: center;
    align-items: center;
}

.container-checkbox-product {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-checkbox-product input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-checkbox-product {
    position: absolute;
    top: 20%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #2196F3;
    border-radius: 5px;
}

.container-checkbox-product:hover input~.checkmark-checkbox-product {
    background-color: #ffffff;
}

.container-checkbox-product input:checked~.checkmark-checkbox-product {
    background-color: #2196F3;
}

.checkmark-checkbox-product:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-product input:checked~.checkmark-checkbox-product:after {
    display: block;
}

.container-checkbox-product .checkmark-checkbox-product:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input.checkbox-cute-plus[type="checkbox"]:after {
    content: '✔';
    color: white;
    /* margin-left: .1rem; */
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    /* margin: auto 0; */
    font-size: 1rem;
    align-items: stretch;
}

input.check-color[type="checkbox"] {
    background: #001f50;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 2px;
    height: 20px !important;
    width: 20px !important;
    display: flex;
    /* position: relative; */
    justify-content: center;
    align-items: center;
}

input.check-color[type="checkbox"]:after {
    content: '✔';
    color: white;
    /* margin-left: .1rem; */
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    /* margin: auto 0; */
    font-size: 1rem;
    align-items: stretch;
}

input.dis-checkbox[disabled] {
    background: #001f50;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 2px;
    height: 20px !important;
    width: 20px !important;
    display: flex;
    /* position: relative; */
    justify-content: center;
    align-items: center;
}

input.dis-checkbox[disabled]:after {
    content: '✔';
    color: white;
    width: 100%;
    display: flex;
    font-size: 1rem;
    height: 100%;
    justify-content: center;
    /* margin: auto 0; */
    align-items: stretch;
}

.scroll-response {
    width: 100%;
    overflow: overlay;
}

.larger {
    width: 20px;
    height: 20px;
}

.margin-response {
    margin-left: 1em !important;
}

.btn-default-row {
    display: flex;
    column-gap: 0.3em;
    border-radius: 20px;
    /* margin-top: 1rem; */
    padding-left: 1.2em;
    align-items: center;
    place-content: center;
    justify-content: flex-start;
}

.checkbox-center {
    align-items: center;
}


@media (max-width: 1040px) {
    .margin-response {
        margin-left: 1em !important;
    }

    /* .scroll-response {
        width: 75vw !important;
        overflow: overlay;
    } */

    .col-gap {
        column-gap: .6rem !important;
    }

    .of-hidden {
        overflow: hidden !important;
    }

    .whiteS {
        white-space: pre-wrap;
    }

    .text-of {
        overflow: hidden !important;
        word-break: break-all !important;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
    }
}

@media (max-width: 380px) {
    .col-gab-response {
        flex: 0 0 33.333333% !important;
        max-width: 33.333333% !important;
        position: relative !important;
        width: 100% !important;
        padding-right: 7.5px !important;
        padding-left: 7.5px !important;
    }

    .margin-response {
        margin-left: 0 !important;
        padding: 0px 1em 1em 1em !important;
    }

    .checkbox-center {
        align-items: start !important;
    }

    .btn-default-row {
        display: flex;
        column-gap: 0.3em;
        border-radius: 20px;
        margin-top: 1rem;
        padding-left: 1.2em;
        align-items: center;
        place-content: center;
        justify-content: flex-start;
    }

    .scroll-response {
        width: 375px !important;
        overflow: overlay;
    }

    .h4-response {
        font-size: 1.1rem !important;
    }

    .larger {
        width: 15px !important;
        height: 15px !important;
    }

    input.dis-checkbox[disabled] {
        background: #001f50;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 2px;
        margin-top: 0.2rem;
        height: 15px !important;
        width: 15px !important;
        display: flex;
        /* position: relative; */
        justify-content: center;
        align-items: center;
    }

    input.dis-checkbox[disabled]:after {
        content: '✔';
        color: white;
        width: 100%;
        display: flex;
        font-size: .8rem !important;
        height: 100%;
        justify-content: center;
        /* margin: auto 0; */
        align-items: stretch;
    }

    input.check-color[type="checkbox"] {
        background: #001f50;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 2px;
        height: 15px !important;
        width: 15px !important;
        display: flex;
        /* position: relative; */
        justify-content: center;
        align-items: center;
    }

    input.check-color[type="checkbox"]:after {
        content: '✔';
        color: white;
        /* margin-left: .1rem; */
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        /* margin: auto 0; */
        font-size: .8rem !important;
        align-items: stretch;
    }

    /* .header-product h4 {
        font-size: 1rem;
    }

    .header-input {
        margin: 0 .3em;
    } 
    
    .h4, h4 {
    font-size: 1.1rem;
}
    */
}