:root {
  --layer-width: 0px;
  --layer-height: 0px;
  --delay-shark: 0s;
  --time-shark: 0s;
  --distance-shark: 0px;
}

/* ----- Outer ----- */
.wordAnimateButton {
  width: 30px;
  height: 30px;
  border: 2px solid #7f7f7f;
  border-radius: 5px;
  padding: 0px;
  outline: none;
  transition: width 1.5s;
  transition-timing-function: ease-in-out;
  color: #7f7f7f;
  overflow: hidden;
  text-overflow: clip;
}

.wordAnimateButton:hover {
  width: 100px;
}

.wordAnimateButton::after {
  content: "";
  animation: changeContentOut 1s ease-in-out forwards,
    hasHovered 1ms paused forwards;
}

.wordAnimateButton:hover::after {
  animation: changeContentIn 2s ease-in-out forwards, hasHovered 1ms forwards;
}

@keyframes changeContentIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    content: "nimation";
  }
}

@keyframes changeContentOut {
  0% {
    opacity: 1;
    content: "nimation";
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hasHovered {
  0% {
    visibility: hidden;
    content: "";
  }

  100% {
    visibility: visible;
  }
}

/* ----- Outer Zone ----- */

/* ----- First Zone ----- */
.main-background {
  /* background-color: black; */
  background-image: var(--mainBackground);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

/* ----- First Zone Sub First ----- */
.base-div-1 {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 65px;
  min-height: 65px; */
  background-color: #00000080;
  border-width: 0px;
  border-radius: 6px;
  padding: 10px;
  margin: 5px;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
  position: relative;
}

.base-div-2 {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 65px;
  min-height: 65px; */
  background-color: #00000080;
  border-width: 0px;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 28px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
}

.day-text-div {
  border-radius: 6px;
  padding: 0px 5px;
}

.base-div-1 .vertical-line-div {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: linear-gradient(#3579f6, #1f4790);
}

/* ----- First Zone Sub First ----- */

/* ----- First Zone Sub Second ----- */
.base-button {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 65px;
  min-height: 65px;
  height: fit-content;
  background-color: #ffffff;
  border-width: 0px;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  font-size: 28px;
  font-weight: bold;
  line-height: normal;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.base-button:hover {
  background-color: #f2f2f2;
}

.base-button:active {
  background-color: #e6e6e6;
  margin-top: 6px;
}

.base-button:focus {
  outline-width: 0px;
}

/* ----- First Zone Sub Second ----- */

/* ----- First Zone ----- */

/* ----- Second Zone ----- */
.waves-position {
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  width: inherit;
}

.waves-box {
  width: 100%;
  height: min(50vw, 50vh);
}

/* .waves-parallax>use {
  animation: move-wave 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.waves-parallax>use.waves-parallax-style-1 {
  animation-duration: 21s;
}

.waves-parallax>use.waves-parallax-style-2 {
  animation-duration: 18s;
}

.waves-parallax>use.waves-parallax-style-3 {
  animation-duration: 15s;
}

.waves-parallax>use.waves-parallax-style-4 {
  animation-duration: 12s;
}

.waves-parallax>use.waves-parallax-style-5 {
  animation-duration: 9s;
}

.waves-parallax>use.waves-parallax-style-6 {
  animation-duration: 6s;
} */

.waves-parallax>path {
  animation: move-wave 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.waves-parallax>path.waves-parallax-style-1 {
  animation-duration: 21s;
}

.waves-parallax>path.waves-parallax-style-2 {
  animation-duration: 18s;
}

.waves-parallax>path.waves-parallax-style-3 {
  animation-duration: 15s;
}

.waves-parallax>path.waves-parallax-style-4 {
  animation-duration: 12s;
}

.waves-parallax>path.waves-parallax-style-5 {
  animation-duration: 9s;
}

.waves-parallax>path.waves-parallax-style-6 {
  animation-duration: 6s;
}

@keyframes move-wave {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* ----- Second Zone ----- */

/* ----- Third Zone ----- */

/* ----- Third Zone Sub First ----- */
.setting-boat-1 img {
  height: min(30vw, 30vh);
}

.setting-boat-2 img {
  height: min(28vw, 28vh);
}

.setting-boat-3 img {
  height: min(32vw, 32vh);
}

.setting-boat-4 img {
  height: min(33vw, 33vh);
}

.setting-boat-all {
  user-select: none;
  position: absolute;
  bottom: min(19vw, 19vh);
  left: 50%;
  transform: translate(-50%, 0);
  animation: move-boat-1 6s ease-in-out infinite;
}

@keyframes move-boat-1 {
  0% {
    bottom: min(19vw, 19vh);
  }

  50% {
    bottom: min(17vw, 17vh);
  }

  100% {
    bottom: min(19vw, 19vh);
  }
}

.setting-person {
  overflow: hidden;
  display: flex;
  position: absolute;
  border-radius: 50%;
  background-color: #FFD027;
  border: solid min(0.5vw, 0.5vh) #FFD027;
}

.setting-person-1 {
  height: min(10.5vw, 10.5vh);
  width: min(10.5vw, 10.5vh);
  top: 3.5%;
  left: 20.5%;
}

.setting-person-1-3 {
  height: min(10.5vw, 10.5vh);
  width: min(10.5vw, 10.5vh);
  top: 3.5%;
  left: 19.5%;
}

.setting-person-2 {
  height: min(10.5vw, 10.5vh);
  width: min(10.5vw, 10.5vh);
  top: -1%;
  left: 18%;
}

.setting-person-3 {
  height: min(11vw, 11vh);
  width: min(11vw, 11vh);
  top: -1%;
  left: 12.5%;
}

.setting-person-4 {
  height: min(12vw, 12vh);
  width: min(12vw, 12vh);
  top: -0.5%;
  left: 8.5%;
}

.setting-person img {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
}

/* ----- Third Zone Sub First ----- */

/* ----- Third Zone Sub Second ----- */
.setting-shark-1 img,
.setting-shark-1 img {
  width: calc(min(24vw, 24vh) - var(--layer-width));
  /* height: min(12.5vw, 12.5vh); */
}

.setting-shark-2 img,
.setting-shark-2 img {
  width: calc(min(26vw, 26vh) - var(--layer-width));
  /* height: min(12.5vw, 12.5vh); */
}

.setting-shark-3 img,
.setting-shark-3 img {
  width: calc(min(28vw, 28vh) - var(--layer-width));
  /* height: min(12.5vw, 12.5vh); */
}

.setting-shark-type-1 {
  user-select: none;
  justify-items: center;
  position: absolute;
  bottom: calc(min(14vw, 14vh) + var(--layer-height));
  right: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  transform: rotateY(0deg);
  animation: move-shark-type-1-part-1 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite,
    move-shark-type-1-part-2 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite,
    move-shark-type-1-part-3 6s ease-in-out infinite;
  animation-delay: var(--delay-shark);
  pointer-events: none;
  cursor: none;
}

@keyframes move-shark-type-1-part-1 {
  0% {
    right: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  }

  50% {
    right: calc((100vw + var(--distance-shark)) + min(18vw, 18vh));
  }

  100% {
    right: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  }
}

@keyframes move-shark-type-1-part-2 {
  0% {
    transform: rotateY(0deg);
  }

  49% {
    transform: rotateY(0deg);
  }

  51% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

@keyframes move-shark-type-1-part-3 {
  0% {
    bottom: calc(min(14vw, 14vh) + var(--layer-height));
  }

  50% {
    bottom: calc(min(10vw, 10vh) + var(--layer-height));
  }

  100% {
    bottom: calc(min(14vw, 14vh) + var(--layer-height));
  }
}

.setting-shark-type-2 {
  user-select: none;
  justify-items: center;
  position: absolute;
  bottom: calc(min(14vw, 14vh) + var(--layer-height));
  left: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  transform: rotateY(180deg);
  animation: move-shark-type-2-part-1 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite,
    move-shark-type-2-part-2 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite,
    move-shark-type-2-part-3 6s ease-in-out infinite;
  animation-delay: var(--delay-shark);
  pointer-events: none;
  cursor: none;
}

@keyframes move-shark-type-2-part-1 {
  0% {
    left: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  }

  50% {
    left: calc((100vw + var(--distance-shark)) + min(18vw, 18vh));
  }

  100% {
    left: calc((0vw - var(--distance-shark)) - min(30vw, 30vh));
  }
}

@keyframes move-shark-type-2-part-2 {
  0% {
    transform: rotateY(180deg);
  }

  49% {
    transform: rotateY(180deg);
  }

  51% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@keyframes move-shark-type-2-part-3 {
  0% {
    bottom: calc(min(14vw, 14vh) + var(--layer-height));
  }

  50% {
    bottom: calc(min(10vw, 10vh) + var(--layer-height));
  }

  100% {
    bottom: calc(min(14vw, 14vh) + var(--layer-height));
  }
}

.status-tooltip {
  width: 15px;
  height: 15px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-right: 10px;
}

.text-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: max-content;
  height: fit-content;
  color: #ffffff;
  border-radius: 6px;
  padding: 0px 10px;
  background-color: #000000cc;
  font-size: 28px;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 10px;
  animation: move-text-tooltip-1 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  animation-delay: var(--delay-shark);
  word-break: break-word;
}

.text-tooltip-type-2 {
  animation: move-text-tooltip-1 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  animation-delay: var(--delay-shark);
}

@keyframes move-text-tooltip-1 {
  0% {
    transform: rotateY(0deg);
  }

  49% {
    transform: rotateY(0deg);
  }

  51% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

.text-tooltip-type-2 {
  animation: move-text-tooltip-2 calc(24s + var(--time-shark)) cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  animation-delay: var(--delay-shark);
}

@keyframes move-text-tooltip-2 {
  0% {
    transform: rotateY(180deg);
  }

  49% {
    transform: rotateY(180deg);
  }

  51% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

/* ----- Third Zone Sub Second ----- */

/* ----- Third Zone ----- */

.animate-modal {
  position: absolute;
  top: 30%;
  right: 4%;
  z-index: 1050;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 800px;
  border-radius: 10px;
}

/* .animate-modal-content {
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.animate-modal-content::-webkit-scrollbar {
  display: none;
} */

.table-style-dashboard-log-animate {
  width: 100%;
  table-layout: fixed;
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* display: block; */
}

.table-style-dashboard-log-animate:hover {
  scrollbar-width: thin;
  /* สำหรับ Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  /* สีโปร่งแสงและพื้นหลังใส */

  /* สำหรับ Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px;
    /* ความกว้าง scrollbar */
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* พื้นหลังโปร่งใส */
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    /* สีโปร่งแสง */
    border-radius: 100px !important;
    /* มุมโค้งมน */
    transition: background 0.3s ease-in-out;
    /* เพิ่มเอฟเฟกต์การเปลี่ยนสี */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.7);
    /* สีเข้มขึ้นเมื่อ hover */
  }
}


.table-style-dashboard-log-animate th,
.table-style-dashboard-log-animate td {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  padding: 8px;
}

.table-row-hover:hover {
  background-color: rgb(248 250 255);
}

.animate-popup-green {
  background-color: #1dc25680 !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.animate-popup-yellow {
  background-color: #ffe22780 !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.animate-popup-red {
  background-color: #e4272680 !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.animate-title {
  font-size: 80px !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.animate-text {
  font-size: 32px !important;
  color: #ffffff !important;
}

.animate-image {
  width: 150px !important;
  height: auto !important;
}

.animate-text-num {
  font-size: 64px !important;
  color: #ffffff !important;
  font-weight: bold !important;
  margin: 0 !important;
}

.animate-text-level {
  font-size: 32px !important;
  color: #ffffff !important;
}

@media (max-width: 1130px) {
  .animate-modal {
    position: absolute;
    z-index: 1050;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 700px;
    border-radius: 10px;
  }
}

@media (max-width: 1020px) {
  .animate-modal {
    position: absolute;
    z-index: 1050;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 600px;
    border-radius: 10px;
  }
}

@media (max-width: 930px) {
  .animate-modal {
    position: absolute;
    z-index: 1050;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 360px;
    border-radius: 10px;
  }
}

@media (max-width: 686px) {
  .animate-modal {
    position: absolute;
    top: 100%;
    left: 4%;
    z-index: 1050;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    border-radius: 10px;
  }
}

@media (max-width: 375px) {
  .animate-modal {
    position: absolute;
    top: 100%;
    left: 4%;
    z-index: 1050;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    border-radius: 10px;
  }
}