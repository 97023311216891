.log-out-line{
    min-width: 1px;
    width: 1px;
    background-color: rgb(255, 255, 255);
    min-height: 25px;
    padding: 0;
    margin: 0px 0px 0px 10px;
    display: block;
}
.logout-btn{
    padding: .375rem .375rem .375rem 5px;
}
.logout-size{
    color: cornsilk;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(0, 64, 142);
}