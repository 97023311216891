 .content-wrapper {
 min-height: none;
  
 }  
.about-img {
  position: inherit;
  width: 100%;
  height: 100%;
}

.body-about-us {
  padding: 5px 5px 5px 5px;
}
.thead-height {
  height: 25px;
  background-color: slategray;
}
.header-footer-bg{
  background-color: slategray;
}


