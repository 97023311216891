@import url(http://fonts.googleapis.com/css?family=Open+Sans);
@import url(http://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);

button {
  cursor: pointer;
  caret-color: transparent;
}

#title {
  /* position: absolute; */
  top: 8px;
  z-index: 100;
}

#performance-panel:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

#performance-panel {
  font-family: 'Inter', sans-serif;
  font-size: 9px;
  color: #f2f6f3;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 10px;
  top: 0px;
  right: 0px;
  justify-content: center;
  z-index: 95;
  transition: background-color 0.3s ease; /* Added transition property */
}

#performance-panel #fps {
  width: 40px;
}

#performance-panel #latency {
  width: 60px;
}


#performance-panel #status {
  width: 100px;
}


#control-panel {
    position: absolute;
    font-size: 14px;
    bottom: 2px;
    left: 20px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.866);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.extend-panel {
  padding: 3px;
  display: flex;
  flex-direction: row;
  position: absolute;
  gap: 3px;
  width: 120px;
  right: 30px;
  bottom: 17px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 4px 15px 0.1px rgba(173, 216, 230, 0.473);
}

#reset-zoom-button {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: inherit;
  font-family: 'Inter', sans-serif;
  outline: none;
}

#reset-zoom-button:hover {
  color: rgba(130, 88, 255);
  background-color: rgba(79, 20, 255, 0.191);
}

#zoom-in-button {
  width: 40px;
  height: 40px;
  padding-top: .3rem;
  font-size: 24px;
  border-radius: 8px;
  background-color: inherit;
  border: none;
  transition: background-color 0.3s ease;
  outline: none;
}

#zoom-in-button:hover {
  color: rgba(130, 88, 255);
  background-color: rgba(79, 20, 255, 0.1);
}

#zoom-out-button {
  padding-top: .3rem;
  font-weight: 700;
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 8px;
  border: none;/* 2 solid #a9abad; */
  background-color: inherit;
  transition: background-color 0.3s ease;
  outline: none;
}

#zoom-out-button:hover {
  color: rgba(130, 88, 255);
  background-color: rgba(79, 20, 255, 0.1);
}


#zoom-out-button:focus {
  border: 1px solid blueviolet;
}
.zoom-out-button:focus {
  border: 1px solid blueviolet;
}

#zoom-out-button-active {
  color: rgba(130, 88, 255);
  background-color: rgba(79, 20, 255, 0.1);
  border: 1px solid blueviolet;
  border-radius: 8px;
}

#axis {
    width: 150px;
    margin: 10px;
    padding: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#compass {
  position: absolute;
  bottom: 20px;
  right: 170px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: azure;
  box-shadow: 5px 4px 15px 0.1px rgba(173, 216, 230, 0.473);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  caret-color: transparent;
}

#north {
  position: absolute;
  width: 0;
  height: 0;
  transform: translateY(-51%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 15px solid red;
}

#center {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#south {
  position: absolute;
  width: 0;
  height: 0;
  transform: translateY(51%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid black;
}


#panel {
    z-index: 100;
    border: 1px solid #E2E6EA;
    border-radius: 4px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#cube-icon {
    font-family: 'Inter', sans-serif;
    position: absolute;
    font-size: 14px;
    top: 45px;
    right: 45px;
    z-index: 90;
    color: white;
}


.reset-button {
  position: absolute;
  bottom: 10px;
  right: 2px;
  z-index: 100;
}

.scene-container{
  position: relative;
}

.cube {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-300px);
    /* transition: transform 1s; */
  }

  .cube__face {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(177, 177, 177);
    line-height: 100px;
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .cube__face {
    /* Existing styles for cube faces */
  
    transition: background 0.3s ease; /* Adjust the duration and timing function as needed */
  }
  
  .cube__face:hover {
    background: rgba(200, 200, 200, 0.95); /* Change the color as needed */
  }

  .cube__face--front {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--right {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--back {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--left {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--top {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--bottom {
    background: rgba(255, 255, 255, 0.95);
  }

  .cube__face--front {
    transform: rotateY(0deg) rotateX(180deg) translateZ(-50px);
  }

  .cube__face--right {
    transform: rotateY(90deg) rotateX(180deg) translateZ(-50px);
  }

  .cube__face--back {
    transform: rotateY(180deg) rotateX(180deg) translateZ(-50px);
  }

  .cube__face--left {
    transform: rotateY(-90deg) rotateX(180deg) translateZ(-50px);
  }

  .cube__face--top {
    transform: rotateX(-90deg) rotateX(180deg) translateZ(-50px);
  }

  .cube__face--bottom {
    transform: rotateX(90deg) rotateX(180deg) translateZ(-50px);
  }

  /* Dream Add */
  .bg-popup-main-wh3d {
    /* height: 160px; */
    margin-left: 4rem;
  }
  .bg-popup-wh3d {
    width: 220px;
    height: 60px;
    background: #00408E;
    border-radius: 10px;
    /* margin-left: 4rem; */
    position: relative;
    display: flex;
  }
  .bg-popup-wh3d-bg {
    width: 220px;
    height: auto;
    background: #00408E;
    border-radius: 10px;
    padding-bottom: 5px;
    box-shadow: -4px 4px 7px 0px #888888;
  }
  .bg-popup-wh3d-header {
    border: 2px whitesmoke solid;
    padding: 10px 5px 5px 5px;
    width: 210px;
    height: 50px;
    border-radius: 10px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bg-popup-wh3d-header > i {
    font-size: 1.5rem;
    padding-bottom: .3rem;
    color: #fff;
    cursor: pointer;
  }
  .icon-wh3d {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #00408E;
    position: absolute;
    left: -55px;
    /* top: 10px; */
    display: flex;
    box-shadow: -4px 4px 7px 0px #888888;
  }
  .icon-bg-wh3d {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: #13A6FD;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-bg-wh3d > i {
    font-size: 1.5rem;
    padding-bottom: .3rem;
    color: #fff;
    /* margin: auto; */
  }
  .bg-popup-wh3d-header-text {
    padding-left: 5px;
    color: #fff;
    font-weight: 800;
  }
  .bg-popup-wh3d-details {
    margin: 5px;
    border-radius: 10px;
    background-color: white;
    max-height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .popup-input-wh3d {
    border-radius: 10px;
    margin: 5px;
    height: 30px;
    border: 1px #00408E solid;
    width: 100%;
  }
  .popup-input-wh3d-disabled {
    border-radius: 10px;
    margin: 5px;
    height: 30px;
    background-color: #dddddd;
    border: 1px #00408E solid;
    width: 100%;
  }
  .grid-popup-input-wh3d {
    display: grid;
  
  }
  .btn-unit-wh3d {
    min-width: 50px;
    font-size: 1.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .popup-status-rack {
    padding: 0px 15px;
    margin: 0 10px;
    background-color: #00408E;
    border-radius: 5px;
    color: white;
    text-align: center;
  }

  /* width */
.bg-popup-wh3d-details::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.bg-popup-wh3d-details::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.bg-popup-wh3d-details::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.bg-popup-wh3d-details::-webkit-scrollbar-thumb:hover {
  background: #13A6FD; 
}
.popup-rack-colors-status {
  width: 20px;
  height: 20px;
}
  /* *********** */