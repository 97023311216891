/* .ant-pagination-item-active {
    border-radius: 0 !important;
}
  
.ant-pagination-item {
    border-radius: 0 !important;
} */
/* .ant-pagination-options-quick-jumper>input{
    border-radius: 0 !important;
} */

.ant-modal-mask {
    z-index: 1050 !important;
}

.ant-modal-wrap {
    z-index: 1051 !important;
}

.ant-modal {
    z-index: 1052 !important;
}

.ant-modal-content {
    z-index: 1053 !important;
}

.text-hover-project-name-all-project {
    color: #000;
}

.text-hover-project-name-all-project:hover {
    color: #007BFF;
    transition: 0.2s ease-in;
}