.rbc-header {
  font-size: 22px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-slot {
  color: black;
  z-index: 1;
}

.rbc-event,
.rbc-background-event {
  z-index: 2;
  padding: 0;
  border: none !important;
}

.rbc-event {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100% !important;
}

.rbc-event-content {
  border: none !important;
  color: black !important;
  background-color: rgb(246, 246, 246) !important;
}

.rbc-timeslot-group {
  height: 50 !important;
}

.rbc-time-header-content,
.rbc-time-content > .rbc-day-slot {
  width: 100% !important;
  min-width: 200px !important;
}

.rbc-header {
  border: none !important;
}

.rbc-time-content:hover {
  overflow: auto; 
}

.rbc-time-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.rbc-time-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rbc-time-content {
  scrollbar-width: none;
}

.rbc-time-content:hover {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0px !important;
}

.rbc-time-header .rbc-overflowing {
  border: none !important;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 0;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin: 0;
}

.rbc-current-time-indicator {
  display: none;
}
