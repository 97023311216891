/* Wrapper styles */
.wrapper {
  background-color: #f8f9fa;
  padding: 20px;
}

/* Form and Input Styles */
.inputGroup {
  margin-bottom: 20px;
}

.inputLabel {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.textInput,
.textArea {
  width: 100%;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 10px;
  transition: border-color 0.3s ease-in-out;
}

.textInput:focus,
.textArea:focus {
  border-color: #4a90e2;
  outline: none;
}

/* Card styles */
.card {
  border: 1px solid #4a90e2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
}

.cardHeader {
  font-weight: bold;
  font-size: 18px;
  color: #004080;
  border-bottom: 2px solid #4a90e2;
  margin-bottom: 20px;
}

.cardTitle {
  font-weight: bold;
}

/* Breadcrumb styles */
.breadcrumbStyle {
  background: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  color: #333333;
}

/* Buttons */
.uploadButton,
.cameraButton {
  background-color: #ececec;
  border-color: #d8d8d8;
  margin-right: 10px;
}

.uploadButton:hover,
.cameraButton:hover {
  background-color: #4a90e2;
  color: #fff;
}

/* Image styles */
.uploadedImage {
  max-width: 100%;
  border: 1px solid #4a90e2;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Output styles */
.outputResult {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #4a90e2;
}

/* Animation for cards */
.card {
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
