.text-header-dashboard-log {
    text-align: center;
    width: 100%;
    background-color: #EBF2FF;
    color: #3579F6;
    font-weight: 800;
    border-radius: 10px;
    padding: 10px 0;
}

.table-style-dashboard-log th,
.table-style-dashboard-log td {
    /* border: 1px solid #ddd; */
    border: none;
    padding: 8px;
    text-align: left;
}

.table-style-dashboard-log thead th {
    text-align: center;
}

.table-style-dashboard-log tbody .table-style-dashboard-log-tr-hover:hover {
    background-color: #f1f1f1;
}

.table-sub-style-dashboard-log-header td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table-sub-style-dashboard-log-header td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table-style-dashboard-log-tr {
    background-color: #f1f1f1;
}

.table-sub-style-dashboard-log-header thead {
    background-color: #EBF2FF;
}

.table-sub-style-dashboard-log-header th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table-sub-style-dashboard-log-header th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}