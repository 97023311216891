@media (max-width: 425px) {
  .changeDirectionToDoList {
    flex-direction: column-reverse;
  }

  .paddingButtonToDoList {
    padding-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .widthSizeStatus {
    max-width: 48.589%;
  }
}

.chartWrapper {
  position: relative;
  height: 200px;
  width: 100%;
}

.chartWrapper canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.chartAreaWrapper2 {
  position: relative;
  height: 200px;
  /* width: 330%; */
  width: 100%;
  min-width: 3000px;
}

/* @media (max-width: 1199px) {
  .chartAreaWrapper2 {
    position: relative;
    height: 200px;
    width: 430%;
  }
}

@media (max-width: 549px) {
  .chartAreaWrapper2 {
    position: relative;
    height: 200px;
    width: 660%;
  }
} */

.customScroll-2 {
  overflow: overlay !important;
}

.customScroll-2::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
}

/* .customScroll-2::-webkit-scrollbar-track {} */

/* .customScroll-2::-webkit-scrollbar-thumb {} */

/* .customScroll-2::-webkit-scrollbar-thumb:hover {} */

/* .customScroll-2:hover::-webkit-scrollbar {} */

.customScroll-2:hover::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-2:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-2:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.fontResultOfYourSuccess_title_1 {
  font-size: 40px;
}

.fontResultOfYourSuccess_title_2 {
  font-size: 35px;
}

.fontResultOfYourSuccess_title_image {
  width: 70px;
  margin-right: 10px;
}

@media (max-width: 424px) {
  .fontResultOfYourSuccess_title_1 {
    font-size: 28px;
  }

  .fontResultOfYourSuccess_title_2 {
    font-size: 23px;
  }

  .fontResultOfYourSuccess_title_image {
    width: 58px;
    margin-right: 10px;
  }

  .flexResultOfYourSuccess_direction {
    display: flex;
    margin: 10px;
    flex-direction: column;
  }
}

.flexResultOfYourSuccess_direction {
  display: flex;
  margin: 10px;
}

.cardWeekResultOfYourSuccess_direction {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cardWeekResultOfYourSuccess_part_1 {
  display: flex;
  width: inherit;
}

.cardWeekResultOfYourSuccess_size {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
  height: 93px;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0px 10px;
}

@media (max-width: 1384px) {
  .flexResultOfYourSuccess_direction {
    display: flex;
    margin: 10px;
    flex-direction: column;
  }

  .cardWeekResultOfYourSuccess_direction {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px 0px;
  }
}

@media (max-width: 979px) {
  .cardWeekResultOfYourSuccess_direction {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cardWeekResultOfYourSuccess_size {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    width: 100%;
    height: 93px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 10px 10px;
  }

  .cardWeekResultOfYourSuccess_part_1 {
    justify-content: center;
  }
}

@media (max-width: 759px) {
  .cardWeekResultOfYourSuccess_part_1 {
    align-items: center;
    flex-direction: column;
  }
}

.chartWrapper_other {
  position: relative;
  height: 500px;
  width: 100%;
}

.chartWrapper_other canvas {
  position: absolute;
  left: 0;
  top: 0;
  /* pointer-events: none; */
}

.chartAreaWrapper_other {
  overflow-x: scroll;
  position: relative;
  width: 100%;
}

.chartAreaWrapper2_other {
  position: relative;
  height: 500px;
  width: 100%;
}

@media (max-width: 1429px) {
  .chartAreaWrapper2_other {
    position: relative;
    height: 500px;
    width: 200%;
  }
}

@media (max-width: 799px) {
  .chartAreaWrapper2_other {
    position: relative;
    height: 500px;
    width: 300%;
  }
}

@media (max-width: 549px) {
  .chartAreaWrapper2_other {
    position: relative;
    height: 500px;
    width: 400%;
  }
}

@media (max-width: 449px) {
  .chartAreaWrapper2_other {
    position: relative;
    height: 500px;
    width: 500%;
  }
}

@media (max-width: 349px) {
  .chartAreaWrapper2_other {
    position: relative;
    height: 500px;
    width: 600%;
  }
}

.MDBDataTable-responsive .row:has(table) {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.MDBDataTable-responsive .row:has(table):hover {
  overflow: auto !important;
}

.MDBDataTable-center th,
.MDBDataTable-center td {
  text-align: center !important;
}

.MDBDataTable-margin-zero .row:has(table) {
  margin: 0px !important;
}

.EmployeeHelpColorBar {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
}

.EmployeeHelpWeekGreen {
  width: 20px;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(
    0deg,
    #ffffff00 0%,
    #7dee7b 50%,
    #1dc256 100%
  );
}

.EmployeeHelpWeekYellow {
  width: 20px;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(
    0deg,
    #ffffff00 0%,
    #ffe794 30%,
    #d4a500 80%
  );
}

.EmployeeHelpWeekRed {
  width: 20px;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(
    0deg,
    #ffffff00 0%,
    #ff415c 40%,
    #4a000a 100%
  );
}

.EmployeeHelpTableRowRed tr:has(> td) {
  background-color: white;
}

.EmployeeHelpTableRowRed
  tr:has(> td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed) {
  background-color: #fdeded !important;
}

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover {
  background-color: #fce4e4 !important;
}

/* .EmployeeHelpTableRowRed
  tr:has(> td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed) {
  background-color: #e4272615;
}

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover {
  background-color: #e4272620 !important;
} */

.EmployeeHelpTableRowRed td:has(.test) {
  background-color: #c1c1c1;
}

.auto_scroll {
  overflow: hidden;
}

.auto_scroll:hover {
  overflow: auto;
}

.auto_scroll_x {
  overflow-x: hidden;
  overflow-y: hidden;
}

.auto_scroll_x:hover {
  overflow-x: auto;
}

.auto_scroll_y {
  overflow-x: hidden;
  overflow-y: hidden;
}

.auto_scroll_y:hover {
  overflow-y: auto;
}

.EmployeeHelpTableRowRed th,
.EmployeeHelpTableRowRed td {
  outline: 1px solid #f0f0f0;
}

.EmployeeHelpTableRowRed th:nth-child(1),
.EmployeeHelpTableRowRed th:nth-child(2),
.EmployeeHelpTableRowRed th:nth-child(3),
.EmployeeHelpTableRowRed th:nth-child(4) {
  background-color: white;
}

.EmployeeHelpTableRowRed td:nth-child(1),
.EmployeeHelpTableRowRed td:nth-child(2),
.EmployeeHelpTableRowRed td:nth-child(3),
.EmployeeHelpTableRowRed td:nth-child(4) {
  /* position: static; */
  background-color: inherit;
}

/* .EmployeeHelpTableRowRed div:nth-child(2) {
  padding: 1px !important;
} */

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover
  td:nth-child(1) {
  background-color: #fce4e4 !important;
}

.EmployeeHelpTableRowRed tr:has(> td):hover td:nth-child(1) {
  background-color: #ececec !important;
}

.EmployeeHelpTableRowRed div:nth-child(1) {
  padding: 1px !important;
}
.EmployeeHelpTableRowRed td:nth-child(0) {
  border-left: 1px solid red;
}

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover
  td:nth-child(2) {
  background-color: #fce4e4 !important;
}

.EmployeeHelpTableRowRed tr:has(> td):hover td:nth-child(2) {
  background-color: #ececec !important;
}

.EmployeeHelpTableRowRed div:nth-child(2) {
  padding: 1px !important;
}

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover
  td:nth-child(3) {
  background-color: #fce4e4 !important;
}

.EmployeeHelpTableRowRed tr:has(> td):hover td:nth-child(3) {
  background-color: #ececec !important;
}

.EmployeeHelpTableRowRed div:nth-child(3) {
  padding: 1px !important;
}

.EmployeeHelpTableRowRed
  tr:has(
    > td .EmployeeHelpColorBar .EmployeeHelpWeekRed.EmployeeHelpRowRed
  ):hover
  td:nth-child(4) {
  background-color: #fce4e4 !important;
}

.EmployeeHelpTableRowRed tr:has(> td):hover td:nth-child(4) {
  background-color: #ececec !important;
}

.EmployeeHelpTableRowRed div:nth-child(4) {
  padding: 1px !important;
}

@media (max-width: 768px) {
  
  .EmployeeHelpTableRowRed th:nth-child(1),
  .EmployeeHelpTableRowRed td:nth-child(1),
  .EmployeeHelpTableRowRed th:nth-child(3),
  .EmployeeHelpTableRowRed td:nth-child(3),
  .EmployeeHelpTableRowRed th:nth-child(4),
  .EmployeeHelpTableRowRed td:nth-child(4) {
    /* position: unset; */
    /* background-color: inherit; */
  }

  .EmployeeHelpTableRowRed td:nth-child(2) {
    position: sticky;
    left: 0px;
    background-color: inherit;
    width: 170px;
    min-width: 170px;
  }

  .EmployeeHelpTableRowRed th:nth-child(2) {
    position: sticky;
    left: 0px;
    background-color: white;
    width: 170px;
    min-width: 170px;
  }
}
