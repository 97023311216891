/* ImageZoom.css */
.figure {
    position: relative;
    display: inline-block;
    width: auto;
    min-height: 25vh;
    background-position: 50% 50%;
    background-color: #eee;
    margin: 0;
    overflow: hidden;
    cursor: zoom-in;
  }
  
  .figure img {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }
  
  .figure.loaded img {
    opacity: 1;
  }
  
  .zoomed {
    cursor: zoom-out;
  }
  
  .loading:before,
  .loading:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #333;
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  