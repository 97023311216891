.grid-con {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* border: 1px solid red; */
    /* grid-template-rows: repeat(1, 300px); */
}

.grid-con-labtop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* border: 1px solid red; */
}

.grid-con-mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* border: 1px solid red; */
}

.grid-item-1 {
    grid-column: 1 / 2;
}

.grid-item-2 {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    grid-column: 2 / 4;
}

.grid-item-3 {
    grid-column: 4 / 5;
}

.grid-item-labtop-1 {
    grid-column: 1 / 2;
}

.grid-item-labtop-2 {
    grid-column: 2 / 3;
}
/* .grid-item-4 {
    grid-column: 4 / 5;
}

.grid-item-5 {
    grid-column: 5 / 6;
} */

@media screen and (max-width: 1400px) {
    .grid-con {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* grid-template-rows: repeat(2, 1fr); */
        /* border: 1px solid red; */
    }
    
    /* .grid-item-1 {
        grid-column: 1 / 3;
    } */
}