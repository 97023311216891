.overflow-tree-style .ant-tree-node-content-wrapper {
    cursor: context-menu !important;
}

.start-icon-favorite-checked {
    color: "#ffaf00" !important;
}

.start-icon-favorite-check {
    color: "#aaa69c";
}

.favorite-list-container {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.favorite-item {
    margin: 0 50px;
    transition: margin 0.3s ease;
}

.favorite-item img {
    max-width: 300px;
    height: auto;
}

.favorite-item h3 {
    text-align: center;
    margin-top: 8px;
}

/* สำหรับ Desktop ใหญ่ */
@media only screen and (min-width: 2560px) {
    .favorite-item {
        margin: 0 25px;
    }
}

/* สำหรับ Desktop กลาง */
@media only screen and (max-width: 1444px) {
    .favorite-item {
        margin: 0 40px;
    }
}

/* สำหรับ Laptop */
@media only screen and (max-width: 1366px) {
    .favorite-item {
        margin: 0 10px;
    }
}

/* สำหรับ Tablet */
@media only screen and (max-width: 768px) {
    .favorite-item {
        margin: 0 15px;
    }
}

/* สำหรับ Mobile */
@media only screen and (max-width: 480px) {
    .favorite-item {
        margin: 0 10px;
    }
}

/* สำหรับ Mobile เล็ก */
@media only screen and (max-width: 320px) {
    .favorite-item {
        margin: 0 10px;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 210px));
    gap: 10px;
    padding: 10px;
}

.grid-item {
    background-color: #eee;
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    user-select: none;
}