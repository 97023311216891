.hiddenScroll {
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hiddenScroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.customScroll-1 {
  overflow: hidden !important;
}

.customScroll-1:hover {
  overflow: overlay !important;
}

.customScroll-1::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
}

/* .customScroll-1:hover:-webkit-scrollbar {
  height: 8px;
} */

.customScroll-1:hover::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll-1:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.rowBrand {
  height: 105px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.colBrand {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.shadowProductService {
  box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.3) !important;
}

.roundedProductService {
  border-radius: 10px !important;
}

.textVerticalOverflow-3 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.textVerticalOverflow-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textVerticalOverflow-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.zI-2 {
  z-index: 2;
}

.buttonIcon,
.buttonIcon:active,
.buttonIcon:focus {
  width: 30px;
  height: 30px;
  border: 2px solid #7F7F7F;
  border-radius: 5px;
  padding: 0px;
  outline: none;
}

.buttonIcon.active {
  border: 2px solid #3579F6;
}

.customSvgBasil {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #7F7F7F;
  --svg: url('../svg/basil_layout-solid.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.customSvgBasil.active {
  background-color: #3579F6;
}

.customSvgRadix {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #7F7F7F;
  --svg: url('../svg/radix-icons_layout.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.customSvgRadix.active {
  background-color: #3579F6;
}

.customSvgChevron {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #3579F6;
  --svg: url('../svg/chevron-down.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  transition: transform .5s ease-in-out;
}

.customSvgChevron.active {
  /* transform: rotate(180deg); */
  transform: rotate3d(1, 0, 0, 180deg);
}

.customSvgEye {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #3579F6;
  --svg: url('../svg/eye.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}


.customSvgPencil {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #FFD027;
  --svg: url('../svg/pencil.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}


.customSvgBin {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #FF415C;
  --svg: url('../svg/bin.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}


.customSvgHourglass {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #AEAEAE;
  --svg: url('../svg/hourglass.svg');
  background: var(--svg) no-repeat 50% 50%;
  background-size: cover;
}

.customSvgClip {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #AEAEAE;
  --svg: url('../svg/clip.svg');
  background: var(--svg) no-repeat 50% 50%;
  background-size: cover;
}

.customSvgCalender {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: transparent;
  background-color: #7F7F7F;
  --svg: url('../svg/calender.svg');
  mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask: var(--svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.customSvgCalender.calenderRed {
  background-color: #FF415C;
}

.customSvgCalender.calenderBlue {
  background-color: #3579F6;
}

/* .react-datepicker__time-container,
.react-datepicker__time-box {
  width: fit-content !important;
} */

.zI-99 {
  z-index: 99;
}
