/* Pagination container */
.d-flex {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

/* Button styles */
.btn-secondary {
    background-color: #ffffff;
    border: 2px solid #4a90e2;
    color: #4a90e2;
    border-radius: 8px;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-secondary:hover {
    background-color: #4a90e2;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-secondary:disabled {
    background-color: #f1f1f1;
    color: #bdbdbd;
    cursor: not-allowed;
}

/* Active page button */
.selected-page {
    background-color: #4a90e2 !important;
    color: #ffffff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

/* Pagination number buttons */
.btn-outline-primary {
    border: 2px solid #00408E;
    background-color: #ffffff;
    color: #00408E;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-outline-primary:hover {
    background-color: #00408E;
    color: #ffffff;
}

/* Margins for buttons */
.mx-2 {
    margin-left: 12px;
    margin-right: 12px;
}

.mx-1 {
    margin-left: 6px;
    margin-right: 6px;
}

/* Fade-in animation for button hover effect */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Smooth animation for the active page */
.selected-page {
    animation: fadeIn 0.5s ease-in-out;
}
