.hr-log-add-datepicker-custom .react-datepicker-wrapper {
    width: 100%;
}

.hr-log-date-disable .form-control.float-left{
  background-color: #f0f0f0;
  color: #6c757d;
  pointer-events: none;
  border: 1px solid #ced4da;
}



.hr-log-status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  
  .hr-log-status.closed {
    background-color: #28a745; 
    border: 1px solid #28a745;
    background-color: #d4edda; 
    color: #0e441b; 
    min-width: 100px;
  }
  
  .hr-log-status.approved {
    background-color: #ffc107; 
    border: 1px solid #ffc107;
    background-color: #ffeeba; 
    color: #856404; 
    min-width: 100px;
  }
  
  .hr-log-status.pending {
    background-color: #dc3545; 
    border: 1px solid #dc3545;
    background-color: #f8d7da; 
    color: #721c24;
    min-width: 100px;
  }
  
  