.year-button.selected {
  background-color: #3579F6;
  color: white;
  border: 1px solid #3579F6;
  border-top-right-radius: 4px ;
  border-bottom-right-radius: 4px ;
}

.year-button {
    font-size: medium;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #CED4DA;
    background-color: #FFFFFF;
    border: 1px solid #CED4DA;
    border-top-right-radius: 4px ;
    border-bottom-right-radius: 4px ;
    padding-inline: 30px;

    transition: border-color 0.4s ease;
    transition: color 0.3s ease;
    transition: background-color 0.3s ease;

}

.month-button.selected {
    background-color: #3579F6;
    color: white;
    border-top: 1px solid #3579F6;
    border-bottom: 1px solid #3579F6;
    border-left: 1px solid #3579F6;
    border-right: 1px solid #3579F6;
  }
  
.month-button {
    font-size: medium;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #CED4DA;
    background-color: #FFFFFF;
    border-top: 1px solid #CED4DA;
    border-bottom: 1px solid #CED4DA;
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding-inline: 20px;

    transition: border-color 0.4s ease;
    transition: color 0.3s ease;
    transition: background-color 0.3s ease;
}

.day-button.selected {
    background-color: #3579F6;
    color: white;
    border: 1px solid #3579F6;
    border-top-left-radius: 4px ;
    border-bottom-left-radius: 4px ;
}
  
.day-button {
    font-size: medium;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #CED4DA;
    background-color: #FFFFFF;
    border: 1px solid #CED4DA;
    border-top-left-radius: 4px ;
    border-bottom-left-radius: 4px ;
    padding-inline: 30px;

    transition: border-color 0.4s ease;
    transition: color 0.3s ease;
    transition: background-color 0.3s ease;

}

.day-button:not(.selected):hover,
.month-button:not(.selected):hover,
.year-button:not(.selected):hover {
  /* Add your hover effect styles here */
  color: #3579F6;
  border-color: #3579F6;
}

.day-button:focus,
.month-button:focus,
.year-button:focus {
    outline: none;
}
