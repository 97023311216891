.expenses-admin-status_1 {
    background-color: #1DC256;
    color: #fff;
}

.expenses-admin-status_2 {
    background-color: #1DC2561C;
    color: #1DC256;
}

.btn-status-all {
    background-color: #1DC256;
    color: #fff;
}

.btn-status-all:hover {
    color: #fff; 
    cursor: pointer; 
}

.btn-not-status-all{
    background-color: #FF0024;
    color: #fff;
}
.btn-not-status-all:hover {
    color: #fff; 
    cursor: pointer; 
}

.expenses-admin-status_3 {
    background-color: #FF00241A;
    color: #FF0024;
}

.expenses-admin-status_4 {
    background-color: #FF0024;
    color: #fff;
}

.btn-expenses-admin-status {
    width: fit-content;
    padding: 0 15px;
    border-radius: 5px;
}

.btn-expenses-admin-status4 {
    width: 65px;
    text-align: center;
    /* padding-top: 3px;
    padding-bottom: 3px; */
    border-radius: 5px;
}

.cursor_expenses_admin_status {
    cursor: pointer;
}

.ant-image-preview-wrap {
    z-index: 1052 !important;
}

.ant-image-preview-mask {
    z-index: 1051 !important;
}

/* USER */
.expenses_user_status_0 {
    background-color: #7aadff;
    color: #fff;
}

.expenses_user_status_1 {
    background-color: #1DC2561C;
    color: #1DC256;
}

.expenses_user_status_2 {
    background-color: #1DC256;
    color: #fff;
}

.expenses_user_status_3 {
    background-color: #FF0024;
    color: #fff;
}

.expenses_user_status_4 {
    background-color: #F9A82524;
    color: #F9A825;
}

.btn-expenses-user-status {
    width: fit-content;
    padding: 0 10px;
    border-radius: 5px;
}

.area-upload-files-expenses-user .ant-upload-select {
    width: 100% !important;
    text-align: center !important;
}

.image_size{
    width: fit-content;
    padding: 0 15px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;  /* Make scrollbar narrower */
    height: 8px; /* Make horizontal scrollbar smaller */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #6f6f6f;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  

  /* @media (max-width: 768px) {
    .Thumbnails {
      max-width: 100%;
      gap: 10px; 
    }
  
    .Thumbnails img {
      width: 50px;  
      height: 50px;
    }
  } */
  